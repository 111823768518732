import { useContext, useEffect, useState } from "react";
import {
    Typography,
    Grid,
    TextField,
    Button,
    CircularProgress,
    Modal,
    Box,
    FormGroup,
    FormControl,
    FormControlLabel,
    Checkbox,
    MenuItem,
    LinearProgress,
} from "@mui/material";
import style from "./userList.module.css";

import Api from "../../api";
import UserContext from "../../context/userContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";

import { empty_user } from "./userList";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

import _ from "lodash";

let debounceEmailPreview;

export const MODAL_EDIT_TYPE = {
    CREATE_NEW_ORDER: 1,
    UPDATE_ORDER: 2,
    UPDATE_ENTRA: 3,
};

export default function EditModal(props) {
    const { show, close, user, setUser, editModalType } = props;
    const { setSnackbarText, userData } = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [loadingEmailValidation, setLoadingEmailValidation] = useState(false);
    const [activateUserDate, setActivateUserDate] = useState(dayjs());
    const [hasInitFields, setHasInitFields] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorFeedback, setErrorFeedback] = useState({});

    useEffect(() => {
        if (show) {
            if (user.email_address) {
                setEmail(user.email_address.split("@")[0]);
                setIsEmailValid(true);
            }
            if (user.activation_date) {
                setActivateUserDate(dayjs(user.activation_date));
            }
            if (
                userData?.tenant.account_types.length > 0 &&
                editModalType === MODAL_EDIT_TYPE.CREATE_NEW_ORDER
            ) {
                setUser((temp) => ({
                    ...temp,
                    account_type: userData?.tenant.account_types[0].group_name,
                }));
            }

            setHasInitFields(true);
        } else {
            setUser(empty_user);
            setEmail("");
            setActivateUserDate(dayjs());
            setErrorFeedback({});
            setHasInitFields(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        debounceEmailPreview = _.debounce(getEmailPreview, 700);
    }, []);

    function updateUser(e) {
        const key = e.target.name;
        let value = e.target.value;
        if (key === "use_manual_email") {
            value = e.target.checked;
        }

        const temp = { ...user, [key]: value };

        const tempErrors = errorFeedback;
        if (key in tempErrors) {
            delete tempErrors[key];
        }
        setUser(temp);
        setErrorFeedback({});
    }

    useEffect(() => {
        if (
            !hasInitFields ||
            editModalType === MODAL_EDIT_TYPE.UPDATE_ENTRA ||
            user?.use_manual_email
        )
            return;

        setIsEmailValid(false);

        if (user.first_name?.length === 0 || user.last_name?.length === 0) {
            setEmail("");
            return;
        }

        const data = {
            first_name: user.first_name,
            last_name: user.last_name,
        };
        debounceEmailPreview(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.first_name, user.last_name, user?.use_manual_email]);

    async function getEmailPreview(data) {
        setLoadingEmailValidation(true);
        try {
            const res = await Api.post("/mail_preview", data);
            setEmail(res.email_username);
            setIsEmailValid(res.is_valid);
        } catch (e) {}

        setLoadingEmailValidation(false);
    }

    async function createUserOrder() {
        try {
            setIsLoading(true);

            user.email_address = `${email}@${userData.tenant.domain}`;
            user.tenant = userData.tenant.id;
            user.created_by = userData.id;
            user.activation_date = activateUserDate.format("YYYY-MM-DD");

            try {
                await Api.post("/users/orders/", user);
                setSnackbarText("Lagt inn bestilling på brukeren");

                close();
            } catch (e) {
                console.log(e);
                if (e.status === 400) {
                    setErrorFeedback(e.data);
                }
            }
        } catch (e) {
            setSnackbarText("Noe gikk galt");
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    async function updateUserOrder() {
        user.email_address = `${email}@${userData.tenant.domain}`;
        user.activation_date = activateUserDate.format("YYYY-MM-DD");

        try {
            setIsLoading(true);

            await Api.put(`/users/orders/${user.id}/`, user);
            setSnackbarText("Oppdatert bestilling på brukeren");
            close();
        } catch (e) {
            console.log(e);
            if (e.status === 400) {
                setErrorFeedback(e.data);
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function updateActiveUser() {
        try {
            setIsLoading(true);

            await Api.put(`/users/${user.id}/`, user);
            setSnackbarText("Oppdatert innstillinger");
            close();
        } catch (e) {
            if (e.status === 400) {
                setErrorFeedback(e.data);
            }
        } finally {
            setIsLoading(false);
        }
    }

    function checkFormError(field: string) {
        return field in errorFeedback;
    }

    function getFormError(field: string) {
        if (field in errorFeedback) {
            return errorFeedback[field];
        }
    }

    function loadingIcon() {
        if (editModalType === MODAL_EDIT_TYPE.UPDATE_ENTRA) return null;
        if (user?.use_manual_email === true) return null;
        if (email.length === 0) return null;

        if (loadingEmailValidation) return <CircularProgress size={20} />;
        if (isEmailValid) return <CheckIcon color="success" />;
        return <ErrorIcon color="warning" />;
    }

    async function delete_user() {
        if (editModalType === MODAL_EDIT_TYPE.UPDATE_ORDER) {
            try {
                await Api.delete(`/users/orders/${user.id}/`);
                setSnackbarText("Slettet ordre på bruker");
                close();
            } catch (e) {
                setSnackbarText("Noe gikk galt");
            }
        }
        if (editModalType === MODAL_EDIT_TYPE.UPDATE_ENTRA) {
            try {
                await Api.delete(`/users/${user.id}/`);
                setSnackbarText("Slettet bruker");
                close();
            } catch (e) {
                setSnackbarText("Noe gikk galt");
            }
        }
    }
    return (
        <Modal
            open={show}
            onClose={() => {
                close();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={style.modal}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant="h6">Brukerdetaljer</Typography>
                    </Grid>
                    <Grid item>
                        {editModalType !== MODAL_EDIT_TYPE.CREATE_NEW_ORDER && (
                            <Button
                                disabled
                                variant="text"
                                color="warning"
                                onClick={delete_user}
                                startIcon={<DeleteIcon />}
                            >
                                Slett
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <FormGroup
                    sx={{ gap: 3, marginTop: "2rem" }}
                    onChange={updateUser}
                >
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <TextField
                                fullWidth
                                error={checkFormError("first_name")}
                                helperText={getFormError("first_name")}
                                name="first_name"
                                label="Fornavn"
                                variant="standard"
                                value={user.first_name}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                fullWidth
                                error={checkFormError("last_name")}
                                helperText={getFormError("last_name")}
                                name="last_name"
                                label="Etternavn"
                                variant="standard"
                                value={user.last_name}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={3}
                        sx={{ paddingTop: "0rem" }}
                        alignItems={"center"}
                    >
                        <Grid item md={5}>
                            <TextField
                                fullWidth
                                disabled={
                                    user?.use_manual_email === false ||
                                    editModalType ===
                                        MODAL_EDIT_TYPE.UPDATE_ENTRA
                                }
                                name="Epost"
                                label="Epost"
                                variant="standard"
                                error={checkFormError("email_address")}
                                helperText={getFormError("email_address")}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                InputProps={{
                                    endAdornment: loadingIcon(),
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            @{userData.tenant?.domain}
                        </Grid>
                    </Grid>

                    <FormControlLabel
                        disabled={
                            editModalType === MODAL_EDIT_TYPE.UPDATE_ENTRA
                        }
                        control={
                            <Checkbox
                                size="small"
                                checked={user?.use_manual_email}
                                name={"use_manual_email"}
                            />
                        }
                        label="Sett epost manuelt"
                    />
                    <TextField
                        fullWidth
                        disabled={
                            editModalType === MODAL_EDIT_TYPE.UPDATE_ENTRA
                        }
                        error={checkFormError("mobile_phone_number")}
                        helperText={getFormError("mobile_phone_number")}
                        name="mobile_phone_number"
                        label="Mobilnummer"
                        variant="standard"
                        value={user.mobile_phone_number}
                    />
                    <TextField
                        name="position"
                        error={checkFormError("position")}
                        helperText={getFormError("position")}
                        label="Stilling"
                        variant="standard"
                        value={user.position}
                    />

                    <FormGroup>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                label="Velg aktiveringsdato"
                                disabled={
                                    MODAL_EDIT_TYPE.UPDATE_ENTRA ===
                                    editModalType
                                }
                                minDate={dayjs()}
                                name="test"
                                onChange={setActivateUserDate}
                                value={activateUserDate}
                                slotProps={{
                                    textField: { variant: "standard" },
                                }}
                            />
                        </LocalizationProvider>
                    </FormGroup>

                    <FormControl sx={{ marginTop: "1rem" }}>
                        <TextField
                            id="account_type_dropdown"
                            value={user.account_type}
                            onChange={updateUser}
                            variant="standard"
                            name="account_type"
                            error={checkFormError("account_type")}
                            label="Brukertype"
                            select
                            disabled={
                                editModalType === MODAL_EDIT_TYPE.UPDATE_ENTRA
                            }
                        >
                            {userData?.tenant.account_types.map((group) => (
                                <MenuItem
                                    key={group.group_name}
                                    value={group.group_name}
                                >
                                    {group.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <div>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            sx={{ marginTop: "1rem", width: "100%" }}
                            onClick={() => {
                                if (
                                    editModalType ===
                                    MODAL_EDIT_TYPE.CREATE_NEW_ORDER
                                ) {
                                    createUserOrder();
                                } else if (
                                    editModalType ===
                                    MODAL_EDIT_TYPE.UPDATE_ORDER
                                ) {
                                    updateUserOrder();
                                } else if (
                                    editModalType ===
                                    MODAL_EDIT_TYPE.UPDATE_ENTRA
                                ) {
                                    updateActiveUser();
                                }
                            }}
                        >
                            {editModalType === MODAL_EDIT_TYPE.CREATE_NEW_ORDER
                                ? "Bestill bruker"
                                : "Lagre endringer"}
                        </Button>
                        {isLoading && (
                            <LinearProgress
                                color="primary"
                                sx={{ padding: "0", margin: "0" }}
                            />
                        )}
                        {isLoading && (
                            <Typography variant="caption">
                                Dette kan ta inntil ett minutt.
                            </Typography>
                        )}
                    </div>
                </FormGroup>
            </Box>
        </Modal>
    );
}
